<template>
  <div class="shipment-alert">
    <!-- Error alert banner -->
    <alert-banner
      v-if="error.show && error.message"
      id="search-credit-alert"
      :animate="{from: 'top', type:'slide'}"
      :has-icon="true"
      :alert-message="error.message"
      alert-type="warning"
      class="alert-floated-fixed bottom-shadow"
      animation
      is-dismissible>
      <p>
        Provided date range is not valid in your subsciption. Please <a
          href="#"
          class="alert-link"
          @click.prevent="openUpgradeModal">upgrade</a> your account to view shipments from this date.
      </p>
    </alert-banner>

    <div class="section-inner">
      <breadcrumb current-page="Shipment Alert Results"/>

      <div class="card-base">
        <div class="card-head-bar">
          <div class="head-title">
            <h1>{{ shipmentAlertResult.name }} alert</h1>
            <sub>Shipment alert for
              <nuxt-link
                to="/shipment-alerts">
                {{ shipmentAlertResult.name }}
              </nuxt-link>
            </sub>
          </div>
        </div>

        <div
          class="search-page">
          <search-data-viewer />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'
import Breadcrumb from '~/components/common/Breadcrumb'
import SearchDataViewer from '~/components/data-viewers/SearchDataViewer'
import EmptyStateNotification from '~/components/common/EmptyStateNotification'
import AlertBanner from '~/components/common/AlertBanner'

export default {
  head() {
    return {
      title:
        this.getPageTitle() +
        ' - ' +
        ISNG_TITLE /*eslint-disable-line no-undef */
    }
  },
  async fetch({ params, error, route, store }) {
    const notFoundError = message =>
      error({
        statusCode: 404,
        message: message
      })

    if (!params.resultId) {
      notFoundError('Shipment alert result ID not found')
      return
    }
    if (!params.shipmentAlertId) {
      notFoundError('Shipment alert ID not found')
      return
    }

    try {
      const { view = 'consignees', p: page = 1, r: limit = 20 } = route.query

      store.commit('views/setActiveAggregateView', view)
      const shipmentAlertIds = {
        shipmentAlertId: params.shipmentAlertId,
        resultId: params.resultId
      }

      store.commit('search/setShipmentAlertIds', shipmentAlertIds)

      await store.dispatch('search/fetchShipmentAlertResult', shipmentAlertIds)

      store.dispatch('search/commitActiveViewCurrentPage', page)
      store.dispatch('search/commitActiveViewPageLimit', limit)
      store.commit('setRootLink', this.shipmentAlertSearchURL)

      store.commit('search/setInitializingPage', false)

      return shipmentAlertIds
    } catch (err) {
      if (err && err.response && err.response.status === 404)
        notFoundError(err.response.data.reason)
      console.error(err)
      return
    }
  },
  name: 'ShipmentAlertResult',
  middleware: ['analyticsSubscriptionIsRequired'],
  components: {
    Breadcrumb,
    SearchDataViewer,
    EmptyStateNotification,
    AlertBanner
  },
  mixins: [openUpgradeModal],
  computed: {
    ...mapState(['error']),
    ...mapState('views', ['activeAggregateView']),
    ...mapGetters('search', [
      'activeViewCurrentPage',
      'activeViewPageLimit',
      'shipmentAlertResult'
    ]),
    ...mapGetters(['shipmentAlertSearchURL']),

    activeViewQueryParams() {
      return {
        view: this.activeAggregateView,
        p: this.activeViewCurrentPage,
        r: this.activeViewPageLimit
      }
    }
  },
  watch: {
    activeViewQueryParams() {
      this.fetchResults()
    },
    $route(to) {
      const {
        query: { p: page, r: limit }
      } = to
      this.commitActiveViewCurrentPage(page)
      this.commitActiveViewPageLimit(limit)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.clearSearch()
    next()
  },
  beforeMount() {
    this.$store.commit('toggleBetaModal', false)
    this.setRootLink(this.shipmentAlertSearchURL)

    const {
      view = 'consignees',
      p: page = 1,
      r: limit = 20
    } = this.$route.query

    this.$router.push({
      path: this.$route.path,
      query: { view, p: page, r: limit }
    })
  },
  methods: {
    ...mapActions('search', [
      'fetchShipmentAlertResult',
      'commitActiveViewCurrentPage',
      'commitActiveViewPageLimit',
      'clearSearch'
    ]),
    ...mapMutations(['setRootLink']),
    ...mapMutations('search', [
      'setInitializingPage',
      'setShipmentAlertIds',
      'setSearchToken'
    ]),
    ...mapMutations('views', ['setActiveAggregateView']),
    fetchResults() {
      this.$router.push(this.shipmentAlertSearchURL)
      this.setRootLink(this.shipmentAlertSearchURL)
    },
    getPageTitle() {
      return (
        this.$store.state.search.shipmentAlertResult.name ||
        'Shipment Alert Results'
      )
    }
  }
}
</script>

<style lang="scss" src="~/assets/scss/sections/breadcrumbs.scss"/>
